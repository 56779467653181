import React, { useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import { httpCall } from 'functions/httpAction';
import { fetchAppLaunch } from 'functions/appLaunch';
import axios from 'axios';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import MaxWidthContainer from 'components/MaxWidthContainer';
import UserAuthModal from 'components/CommentDetailModal/UserAuthModal';
import AdditionalPhotoModal from 'components/CommentDetailModal/AdditionalPhotoModal';
import CommentModal from 'components/CommentDetailModal/CommentModal';
import { getFileSize } from 'utils/getFileSize';
import { firebaseAuth } from 'utils/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

import { usePathname } from 'next/navigation';
import redirectToLogin from 'utils/redirectToLogin';
import { MixpanelConfig } from 'utils/mixpanelConfig';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

const CommentPopup = (props) => {
  // const { ...restProps } = props;
  const {
    externalPopupShown,
    setExternalPopupShown,
    externalProductID,
    setExternalProductID,
    externalProductBrandName,
    setExternalProductBrandName,
    externalProductName,
    setExternalProductName,
  } = props;

  // const [arrNotificationState, setArrNotificationState] = useState(null);
  // const [arrStoresState, setArrStoresState] = useState(null);
  // const [arrProductOriginsState, setArrProductOriginsState] = useState(null);
  // const [arrCategoryState, setArrCategoryState] = useState([]);
  // const [arrSearchSkinProblemState, setArrSearchSkinProblemState] = useState(
  //   []
  // );
  // const [arrBannerState, setArrBannerState] = useState([]);

  //from Firebase
  const [isUserLoggedIn, setIsUserLoggeedIn] = useState(false);
  const [firUser, setFirUser] = useState(null);

  const [popupShown, setPopupShown] = useState(false);
  const [popupState, setPopupState] = useState(0); //0: comment ; 1: add product

  const [searchProductInputFocusState, setSearchProductInputFocusState] =
    useState(false);
  const [searchProductIdState, setSearchProductIdState] = useState('');
  const [searchProductKeywordState, setSearchProductKeywordState] =
    useState('');
  const [searchProductSecondCatIdState, setSearchProductSecondCatIdState] =
    useState('');

  const [searchProductsLoadingState, setSearchProductsLoadingState] =
    useState(false);
  const [arrSearchProductsState, setArrSearchProductsState] = useState([]);

  const [blockSearchProductState, setBlockSearchProductState] = useState('');
  const [arrSearchCatState, setArrSearchCatState] = useState([]);

  const [commentOverviewState, setCommentOverviewState] = useState(2);
  const arrCommentOverviewContent = [
    { mark: 1, title: '❤️好評' },
    { mark: 0, title: '👌中性' },
    { mark: -1, title: '👿差評' },
  ];

  const [detailCommentState, setDetailCommentState] = useState('');
  const [detailCommentCountState, setDetailCommentCountState] = useState(0);

  const [textureRankState, setTextureRankState] = useState(2);
  const [textureIdState, setTextureIdState] = useState(0);
  const [favorRankState, setFavorRankState] = useState(2);
  const [favorIdState, setFavorIdState] = useState(0);
  const [useableRankState, setUseableRankState] = useState(2);
  const [useableIdState, setUseableIdState] = useState(0);
  const dottedArray = [1, 2, 3, 4, 5];
  const [blockRankPointState, setBlockRankPointState] = useState('');

  const [isUserAuthModalShow, setIsUserAuthModalShow] = useState(false);
  const [isAdditionalPhotoModalShow, setIsAdditionalPhotoModalShow] =
    useState(false);
  const [isCommentModalShow, setIsCommentModalShow] = useState(false);

  const [isUserAuthSessionShow, setIsUserAuthSessionShow] = useState(false);
  const [isUserAdditionalSessionShow, setIsUserAdditionalSessionShow] =
    useState(false);
  const [isCommentSessionShow, setIsCommentSessionShow] = useState(false);

  const [arrAuthImage, setArrAuthImage] = useState([]);
  const [blockAuthImage, setBlockAuthImage] = useState('');
  const authImageRef = useRef(null);

  const [arrAdditionalImage, setArrAdditionalImage] = useState([]);
  const [blockAdditionalImage, setBlockAdditionalImage] = useState('');
  const additionalImageRef = useRef(null);

  const [isCertTutorialShow, setIsCertTutorialShow] = useState(false);
  const [isComparisonTutorialShow, setIsComparisonTutorialShow] =
    useState(false);
  const [isCommentTutorialShow, setIsCommentTutorialShow] = useState(false);

  const scrollRef = useRef(null);
  const barHeight = 96;

  // const rankArrays = [
  //     { title: '質地', value: textureRankState, setValue: setTextureRankState, startLabel: '較流質', endLabel: '較濃稠' },
  //     { title: '香味', value: favorRankState, setValue: setFavorRankState, startLabel: '無香', endLabel: '濃郁' },
  //     { title: '成效', value: useableRankState, setValue: setUseableRankState, startLabel: '無感', endLabel: '有感' }
  // ]

  const setValueArray = [
    {
      value: textureRankState,
      setValue: setTextureRankState,
      index: textureIdState,
      setIndex: setTextureIdState,
    },
    {
      value: favorRankState,
      setValue: setFavorRankState,
      index: favorIdState,
      setIndex: setFavorIdState,
    },
    {
      value: useableRankState,
      setValue: setUseableRankState,
      index: useableIdState,
      setIndex: setUseableIdState,
    },
  ];
  const [arrReviewMetricsState, setArrReviewMetricsState] = useState([]);
  const [commentCountMaxState, setCommentCountMaxState] = useState(0);
  const [commentValidCountState, setCommentValidCountState] = useState(0);
  const [commentValidScoreState, setCommentValidScoreState] = useState(0);

  const [commentHintShow, setCommentHintShow] = useState(true);
  const [isProductNotFilled, setIsProductNotFilled] = useState(false);
  const [isCommentNotFilled, setIsCommentNotFilled] = useState(false);
  const [isRankNotFilled, setIsRankNotFilled] = useState(false);
  const [isCommentTooLong, setIsCommentTooLong] = useState(false);

  const [isAddBrandNotFilled, setIsAddBrandNotFilled] = useState(false);
  const [isAddNameNotFilled, setIsAddNameNotFilled] = useState(false);

  const [isUploadProcessing, setIsUploadProcessing] = useState(false);
  const [hintText, setHintText] = useState('');
  const [initialHintText, setInitialHintText] = useState('');

  const [isPostError, setIsPostError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const pathname = usePathname();

  // let localStorageIsCommentModalShown = ''

  function appLaunch() {
    fetchAppLaunch().then(([success, error, data]) => {
      // console.log({ data });
      // console.log('dllm')
      // console.log(data);

      // if (data.notifications) {
      //   setArrNotificationState(data.notifications);
      // }

      // if (data.stores) {
      //   setArrStoresState(data.stores);
      // }

      // if (data.product_origins) {
      //   setArrProductOriginsState(data.product_origins);
      // }

      if (data && data.category_tree) {
        // setArrCategoryState(data.category_tree);

        var arrSearchCatTmp = [];

        data.category_tree.forEach((element) => {
          element.second_categories.forEach((elementSecond) => {
            arrSearchCatTmp.push(elementSecond);
          });
        });

        setArrSearchCatState(arrSearchCatTmp);
      }

      // if (data.search_skin_problem_keywords) {
      //   setArrSearchSkinProblemState(data.search_skin_problem_keywords);
      // }

      if (data && data.review_options.review_metrics) {
        setArrReviewMetricsState(data.review_options.review_metrics);
      }

      if (data && data.review_options.comment_length_limit) {
        setCommentCountMaxState(data.review_options.comment_length_limit);
      }

      if (data && data.review_options.comment_valid_length) {
        setCommentValidCountState(data.review_options.comment_valid_length);
      }

      if (data && data.review_options.comment_valid_score) {
        setCommentValidScoreState(data.review_options.comment_valid_score);
      }

      if (data && data.review_options.review_question) {
        setHintText(data.review_options.review_question);
        setInitialHintText(data.review_options.review_question);
      }

      checkSignIn();
    });
  }

  async function fetchProfile() {
    const auth = firebaseAuth;
    const user = auth.currentUser;

    if (user != null) {
      const token = user.accessToken;

      try {
        const [success, error, statusCode, data] = await httpCall(
          'get',
          process.env.NEXT_PUBLIC_API_HOST + '/api/v2/me/',
          token,
          null
        );

        if (success == true) {
          // console.log({ data });
          setIsCertTutorialShow(data.show_review_cert_tutorial);
          setIsComparisonTutorialShow(data.show_review_comparison_tutorial);
          setIsCommentTutorialShow(data.show_review_text_tutorial);
        } else {
          console.log('api error', error);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  async function checkSignIn() {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setIsUserLoggeedIn(true);
        setFirUser(user);
        fetchProfile();
      } else {
        setIsUserLoggeedIn(false);
        setFirUser(null);
      }
    });

    if (externalProductID == null) {
      fetchSearch();
    } else {
      selectExternalProduct();
    }
  }

  function pressSearchProductInputFocus(focused) {
    if (focused == true) {
      setSearchProductInputFocusState(true);
      setSearchProductKeywordState('');
      setSearchProductIdState('');

      if (searchProductKeywordState != '' && searchProductIdState != '') {
        setArrSearchProductsState([]);
        fetchSearch();
      }
    } else {
      setTimeout(() => {
        setSearchProductInputFocusState(false);
      }, 100);
    }
  }

  function pressAddBrandInputFocus(focused) {
    setAddBrandInputFocusState(focused);

    if (focused == true) {
      if (addBrandState != '') {
        setArrAddBrandsState([]);
        fetchSearchBrands();
      }
    }
  }

  function selectExternalProduct() {
    setSearchProductKeywordState(
      (externalProductBrandName == null ? '' : externalProductBrandName) +
        (externalProductName == null ? '' : ' ' + externalProductName)
    );
    setSearchProductIdState(externalProductID == null ? '' : externalProductID);

    // setSearchProductKeywordState(element.brand.name + " " + element.name)
    // setSearchProductIdState(element.id)
    // setSearchProductSecondCatIdState(element.second_categories[0]?.id ?? 0)

    setSearchProductInputFocusState(false);
  }

  async function fetchSearch() {
    setSearchProductsLoadingState(true);

    try {
      const [success, error, statusCode, data] = await httpCall(
        'get',
        process.env.NEXT_PUBLIC_API_HOST +
          '/api/v2/search?keyword=' +
          searchProductKeywordState +
          '&init_product_count=4&limit=8',
        null,
        null
      );

      setSearchProductsLoadingState(false);

      if (success == true) {
        if (data.products != null) {
          var arrProductTmp = [];

          data.products.forEach((element) => {
            arrProductTmp.push(element);
          });

          setArrSearchProductsState(arrProductTmp);
        }
      } else {
        console.log('api error', error);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  async function fetchSearchBrands() {
    setSearchAddBrandsLoadingState(true);

    try {
      const [success, error, statusCode, data] = await httpCall(
        'get',
        process.env.NEXT_PUBLIC_API_HOST +
          '/api/v2/search?keyword=' +
          addBrandState +
          '&init_product_count=4&limit=8',
        null,
        null
      );

      setSearchAddBrandsLoadingState(false);

      if (success == true) {
        if (data.products != null) {
          var arrBrandTmp = [];

          console.log(data);

          data.brands.forEach((element) => {
            arrBrandTmp.push(element);
          });

          setArrAddBrandsState(arrBrandTmp);
        }
      } else {
        console.log('api error', error);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  function loadBlockSearchProduct() {
    var blockSearchProductTmp = [];

    if (searchProductsLoadingState == true) {
      blockSearchProductTmp.push(
        <div className="flex h-full w-full items-center justify-center">
          <div className="space-y-3">
            <img
              className="m-[auto] h-[160px] w-[160px]"
              src="/images/search-placeholder.gif"
            ></img>
          </div>
        </div>
      );
    } else {
      arrSearchProductsState.forEach(function (element, index) {
        var imageURL = '';

        if (element.images.length > 0) {
          imageURL = element.images[0].url;
        }

        blockSearchProductTmp.push(
          <div
            className="flex h-[40px] cursor-pointer space-x-[16px]"
            onClick={() => {
              setSearchProductKeywordState(
                element.brand.name + ' ' + element.name
              );
              setSearchProductIdState(element.id);
              setSearchProductSecondCatIdState(
                element.second_categories[0]?.id ?? 0
              );

              setSearchProductInputFocusState(false);
            }}
          >
            <img src={imageURL} className="h-[40px] w-[40px]"></img>
            <div className="h-full w-full">
              <div className="text-sm font-medium text-sorra-gray-900">
                {element.name}
              </div>
              <div className="pt-0.5 text-sm font-medium text-sorra-gray-800">
                {element.brand.name}
              </div>
            </div>
          </div>
        );
      });
    }

    setBlockSearchProductState(blockSearchProductTmp);
  }

  function loadBlockAddBrand() {
    var blockAddBrandTmp = [];

    if (searchAddBrandsLoadingState == true) {
      blockAddBrandTmp.push(
        <div className="flex h-full w-full items-center justify-center">
          <div className="space-y-3">
            <img
              className="m-[auto] h-[160px] w-[160px]"
              src="/images/search-placeholder.gif"
            ></img>
          </div>
        </div>
      );
    } else {
      arrAddBrandsState.forEach(function (element, index) {
        var imageURL = '';

        if (element.image != null) {
          imageURL = element.image.url;
        } else {
          imageURL = 'images/placeholders/brand.png';
        }

        blockAddBrandTmp.push(
          <div
            className="flex h-[40px] cursor-pointer space-x-[16px]"
            onClick={() => {
              console.log({ element });
              setAddBrandState(element.name);
              setAddBrandInputFocusState(false);
            }}
          >
            <img src={imageURL} className="h-[40px] w-[40px]"></img>
            <div className="h-full w-full">
              <div className="text-sm font-medium text-sorra-gray-900">
                {element.name}
              </div>
              <div className="pt-0.5 text-sm font-medium text-sorra-gray-800">
                {element.name}
              </div>
            </div>
          </div>
        );
      });
    }

    setBlockAddBrandState(blockAddBrandTmp);
  }

  function loadBlockCommentPointsState() {
    const blockCommentPointsStateTmp = [];

    arrReviewMetricsState.forEach(function (item, index) {
      const targetValue = setValueArray[index];
      targetValue.setIndex(arrReviewMetricsState[index].id);
      blockCommentPointsStateTmp.push(
        <div className="mt-[24px]">
          <div className="flex items-center">
            <div className="mr-[24px] font-[500]">{item.name}</div>
            <div className="flex flex-grow flex-col">
              <div className="text-grey-800 flex items-center justify-between text-[12px]">
                <div>{item.low}</div>
                <div>{item.high}</div>
              </div>
              <div className="mt-[12px]">
                <Slider
                  min={0}
                  max={4}
                  defaultValue={2}
                  // value={useableRankState}
                  onChange={(value) => {
                    targetValue.setValue(value);
                  }}
                  trackStyle={{ backgroundColor: '#F16B7C', height: '8px' }}
                  railStyle={{ backgroundColor: '#E5E7EB', height: '8px' }}
                  handleStyle={{
                    borderColor: '#E7E7E7',
                    borderWidth: '1px',
                    backgroundColor: 'white',
                    marginTop: '-4px',
                    width: '16px',
                    height: '16px',
                    opacity: '1',
                  }}
                />
                <div className="mt-[8px] flex items-center justify-between">
                  {dottedArray.map((_, idx) => (
                    <div
                      key={idx}
                      className="h-[4px] w-[4px] rounded-[50%] bg-[#D9D9D9]"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    setBlockRankPointState(blockCommentPointsStateTmp);
  }

  function loadBlockAuthImage() {
    const blockAuthImageTmp = [];

    const clickAuthRef = () => {
      if (isCertTutorialShow && !isUserAuthSessionShow) {
        setIsUserAuthModalShow(true);
        setIsUserAuthSessionShow(true);
      } else {
        authImageRef.current.click();
      }
    };

    const removeContent = (index) => {
      arrAuthImage.splice(index, 1);
      setArrAuthImage([...arrAuthImage]);
    };

    const addFileContent = (files, isContinueUpload = false) => {
      const checkSize = !isContinueUpload
        ? files.length
        : arrAuthImage.length + files.length;

      if (checkSize <= 1) {
        const tmpArray = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const { size } = file;
          if (size > 4000000) {
            alert('每張圖片大小上限為4MB！');
            return;
          } else {
            tmpArray.push(file);
          }
        }
        const placeArray = [...arrAuthImage, ...tmpArray];
        setArrAuthImage(placeArray);
      } else {
        alert('上傳上限為1張圖片');
      }
    };

    if (arrAuthImage.length == 0) {
      blockAuthImageTmp.push(
        <div
          className="mt-[12px] flex cursor-pointer items-center rounded-lg border p-[24px] text-gray-800"
          onClick={clickAuthRef}
        >
          <img
            src="/svgs/upload.svg"
            width={20}
            height={20}
            alt=""
            className="mr-[8px] mt-[2px]"
          />
          背面或底部的照片，或購買憑證
          <input
            type="file"
            multiple="multiple"
            accept="image/*"
            id="additionalImage"
            hidden
            ref={authImageRef}
            onChange={(event) => addFileContent(event.target.files)}
          />
        </div>
      );
    } else {
      const itemContentTmp = [];

      arrAuthImage.forEach(function (item, index) {
        itemContentTmp.push(
          <div className="border-b-[1px] border-[#D1D5DB] px-[16px] py-[24px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div>
                  <Image
                    src={URL.createObjectURL(item)}
                    width={40}
                    height={40}
                  />
                </div>
                <div className="flex flex-col space-y-1">
                  <div className="text-[14px] font-medium text-[#111928]">
                    {item.name}
                  </div>
                  <div className="text-[14px] font-medium text-sorra-gray-800">
                    {getFileSize(item.size)}
                  </div>
                </div>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => removeContent(index)}
              >
                <Image src="/svgs/trash.svg" width={20} height={20} alt="" />
              </div>
            </div>
          </div>
        );
      });

      blockAuthImageTmp.push(
        <div className="mt-[12px] cursor-pointer rounded-lg border-[1px] border-[#D1D5DB]">
          {itemContentTmp}

          {itemContentTmp.length < 10 ? (
            <div
              className="flex items-center px-[24px] py-[24px]"
              onClick={clickAuthRef}
            >
              <img
                src="/svgs/upload.svg"
                width={20}
                height={20}
                alt=""
                className="mr-[8px] mt-[2px]"
              />
              上傳認證照
              <input
                type="file"
                multiple="multiple"
                accept="image/*"
                id="additionalImage"
                hidden
                ref={authImageRef}
                onChange={(event) => addFileContent(event.target.files, true)}
              />
            </div>
          ) : null}
        </div>
      );
    }

    setBlockAuthImage(blockAuthImageTmp);
  }

  function loadBlockAdditionalImage() {
    const blockAdditionalImageTmp = [];

    const clickAdditionalRef = () => {
      // const localStorageIsAdditionalModalShown = localStorage.getItem('isAdditionalModalNotShow')

      if (isComparisonTutorialShow && !isUserAdditionalSessionShow) {
        setIsAdditionalPhotoModalShow(true);
        setIsUserAdditionalSessionShow(true);
      } else {
        additionalImageRef.current.click();
      }
    };

    const removeContent = (index) => {
      arrAdditionalImage.splice(index, 1);
      setArrAdditionalImage([...arrAdditionalImage]);
    };

    const addFileContent = (files, isContinueUpload = false) => {
      const checkSize = !isContinueUpload
        ? files.length
        : arrAdditionalImage.length + files.length;

      // console.log({ checkSize })

      if (checkSize <= 3) {
        const tmpArray = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const { size } = file;
          if (size > 4000000) {
            alert('每張圖片大小上限為4MB！');
            return;
          } else {
            tmpArray.push(file);
          }
        }
        const placeArray = [...arrAdditionalImage, ...tmpArray];
        setArrAdditionalImage(placeArray);
      } else {
        alert('上傳上限為3張圖片');
      }
    };

    if (arrAdditionalImage.length == 0) {
      blockAdditionalImageTmp.push(
        <div
          className="mt-[12px] flex cursor-pointer items-center rounded-lg border p-[24px] text-gray-800"
          onClick={clickAdditionalRef}
        >
          <img
            src="/svgs/upload.svg"
            width={20}
            height={20}
            alt=""
            className="mr-[8px] mt-[2px]"
          />
          前後對比圖／妝容效果／試色／質地
          <input
            type="file"
            multiple="multiple"
            accept="image/*"
            id="additionalImage"
            hidden
            ref={additionalImageRef}
            onChange={(event) => addFileContent(event.target.files)}
          />
        </div>
      );
    } else {
      const itemContentTmp = [];

      arrAdditionalImage.forEach(function (item, index) {
        itemContentTmp.push(
          <div className="border-b-[1px] border-[#D1D5DB] px-[16px] py-[24px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div>
                  <Image
                    src={URL.createObjectURL(item)}
                    width={40}
                    height={40}
                    alt=""
                  />
                </div>
                <div className="flex flex-col space-y-1">
                  <div className="text-[14px] font-medium text-[#111928]">
                    {item.name}
                  </div>
                  <div className="text-[14px] font-medium text-sorra-gray-800">
                    {getFileSize(item.size)}
                  </div>
                </div>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => removeContent(index)}
              >
                <Image src="/svgs/trash.svg" width={20} height={20} alt="" />
              </div>
            </div>
          </div>
        );
      });

      blockAdditionalImageTmp.push(
        <div className="mt-[12px] cursor-pointer rounded-lg border-[1px] border-[#D1D5DB]">
          {itemContentTmp}

          {itemContentTmp.length < 10 ? (
            <div
              className="flex items-center px-[24px] py-[24px]"
              onClick={clickAdditionalRef}
            >
              <img
                src="/svgs/upload.svg"
                width={20}
                height={20}
                alt=""
                className="mr-[8px] mt-[2px]"
              />
              上傳附加照片
              <input
                type="file"
                multiple="multiple"
                accept="image/*"
                id="additionalImage"
                hidden
                ref={additionalImageRef}
                onChange={(event) => addFileContent(event.target.files, true)}
              />
            </div>
          ) : null}
        </div>
      );
    }

    setBlockAdditionalImage(blockAdditionalImageTmp);
  }

  const resetWarningState = () => {
    setIsCommentNotFilled(false);
    setIsProductNotFilled(false);
    setIsRankNotFilled(false);
    setIsCommentTooLong(false);
  };

  const postComment = async () => {
    setErrorMsg('');

    setIsProductNotFilled(searchProductIdState == '');
    setIsRankNotFilled(commentOverviewState == 2);
    setIsCommentNotFilled(detailCommentState == '');
    setIsCommentTooLong(detailCommentState.length > commentCountMaxState);

    const score =
      (detailCommentState.length > commentValidCountState
        ? commentValidScoreState
        : 0) +
      (arrAdditionalImage.length > 0 ? 5 : 0) +
      (arrAuthImage.length > 0 ? 10 : 0);
    MixpanelConfig.track('product_commented', {
      product: searchProductKeywordState,
      score: score,
      polarity:
        commentOverviewState == 0
          ? 'neutral'
          : commentOverviewState == 1
            ? 'postive'
            : commentOverviewState == -1
              ? 'negative'
              : '',
    });

    if (searchProductIdState == '') {
      if (scrollRef.current) {
        scrollRef.current.style.scrollBehavior = 'smooth';
        scrollRef.current.scrollTop = 0;
      }
      return;
    } else if (searchProductIdState != '' && commentOverviewState == 2) {
      const productBox = document.getElementById('rank_ranking');
      const topPos = productBox.offsetTop;
      if (scrollRef.current) {
        scrollRef.current.style.scrollBehavior = 'smooth';
        scrollRef.current.scrollTop = topPos;
      }
      return;
    } else if (
      searchProductIdState != '' &&
      commentOverviewState != 2 &&
      detailCommentState == ''
    ) {
      const productBox = document.getElementById('rank_comment');
      const topPos = productBox.offsetTop;
      if (scrollRef.current) {
        scrollRef.current.style.scrollBehavior = 'smooth';
        scrollRef.current.scrollTop = topPos;
      }
      return;
    } else if (
      searchProductIdState != '' &&
      commentOverviewState != 2 &&
      detailCommentState != '' &&
      detailCommentState.length > commentCountMaxState
    ) {
      const productBox = document.getElementById('rank_comment');
      const topPos = productBox.offsetTop;
      if (scrollRef.current) {
        scrollRef.current.style.scrollBehavior = 'smooth';
        scrollRef.current.scrollTop = topPos;
      }
      return;
    }

    if (!isRankNotFilled && !isProductNotFilled && !isCommentNotFilled) {
      setIsUploadProcessing(true);
      const auth = firebaseAuth;
      const user = auth.currentUser;
      const formData = new FormData();

      const commentData = {
        product_id: searchProductIdState,
        comment: detailCommentState,
        score: commentOverviewState,
        review_metrics: [
          { id: textureIdState, score: textureRankState },
          { id: favorIdState, score: favorRankState },
          { id: useableIdState, score: useableRankState },
        ],
      };
      formData.append('data', JSON.stringify(commentData));

      arrAuthImage.forEach(function (file, index) {
        formData.append('files.cert_images', file, file.name);
      });

      arrAdditionalImage.forEach(function (file, index) {
        formData.append('files.comparison_images', file, file.name);
      });

      axios
        .request({
          method: 'POST',
          headers: { Authorization: `Bearer ${user.accessToken}` },
          url: `${process.env.NEXT_PUBLIC_API_HOST}/api/v2/me/review`,
          data: formData,
        })
        .then((_) => {
          setIsUploadProcessing(false);
          console.log('postComment successfully');
          window.location = `/product/reviews/${searchProductIdState}`;
        })
        .catch((err) => {
          setIsUploadProcessing(false);
          setIsPostError(true);
          setErrorMsg(err.response.data.message);
        });
    }
  };

  function checkHintText(isValid = true) {
    if (!isValid) {
      setCommentHintShow(true);
      setHintText(initialHintText);
    } else {
      const target = arrSearchCatState.find(
        (item) => item.id == searchProductSecondCatIdState
      );
      setCommentHintShow(true);
      setHintText(target.review_question);
    }
  }

  useEffect(() => {
    loadBlockSearchProduct();
  }, [arrSearchProductsState, searchProductsLoadingState]);

  // useEffect(() => {
  //     loadBlockAddBrand();
  // }, [arrAddBrandsState, searchAddBrandsLoadingState])

  useEffect(() => {
    fetchSearch();
  }, [searchProductKeywordState]);

  // useEffect(() => {
  //     fetchSearchBrands();
  // }, [addBrandState])

  useEffect(() => {
    loadBlockCommentPointsState();
  }, [arrReviewMetricsState]);

  useEffect(() => {
    appLaunch();
    // localStorageIsCommentModalShown = localStorage.getItem('isCommentModalNotShow');
  }, []);

  useEffect(() => {
    selectExternalProduct();
  }, [
    externalProductID,
    externalProductBrandName,
    externalProductName,
    externalPopupShown,
    popupShown,
  ]);

  useEffect(() => {
    loadBlockAuthImage();
  }, [arrAuthImage, isCertTutorialShow, isUserAuthSessionShow]);

  useEffect(() => {
    // console.log({ arrAdditionalImage })
    loadBlockAdditionalImage();
  }, [
    arrAdditionalImage,
    isComparisonTutorialShow,
    isUserAdditionalSessionShow,
  ]);

  useEffect(() => {
    if (searchProductSecondCatIdState) {
      checkHintText();
    } else {
      checkHintText(false);
    }
  }, [searchProductSecondCatIdState]);

  return (
    <>
      <div
        className={
          'fixed bottom-0 left-0 z-[60] h-full w-full lg:bottom-[144px] lg:h-[calc(100vh-144px)]' +
          (popupShown == true || externalPopupShown == true
            ? ' block'
            : ' hidden')
        }
        style={{ pointerEvents: popupShown == false ? 'none' : 'auto' }}
        id="comment_box"
        onClick={() => {
          if (popupShown == true) {
            setPopupShown(false);
            setExternalPopupShown(false);
            setPopupState(0);
          }
        }}
      >
        <MaxWidthContainer className="flex h-full w-full justify-end">
          <div
            className="commentPopup-container h-full w-full overflow-hidden rounded-none bg-white lg:mb-[0px] lg:mt-[auto] lg:max-h-[670px] lg:w-[480px] lg:rounded-lg"
            style={{ pointerEvents: 'auto' }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="relative top-0 z-30 w-full bg-white">
              <div className="left-0 top-0 h-[96px] w-full px-[16px] py-[24px]">
                <button
                  className="border-text-gray-100 absolute left-[16px] mb-2 mr-2 h-[48px] w-[48px] rounded-lg border p-[14px] text-center text-sm font-medium text-gray-100 focus:outline-none focus:ring-4 focus:ring-blue-300"
                  onClick={() => {
                    setPopupShown(false);
                    setExternalPopupShown(false);
                    setPopupState(0);
                  }}
                >
                  <img
                    src="/svgs/modal-close.svg"
                    className="h-[20px] w-[20px]"
                  ></img>
                </button>

                <div className="flex h-[48px] items-center justify-center text-center text-lg font-medium text-sorra-gray-800 lg:hidden">
                  留評價
                </div>
              </div>
            </div>

            <div
              ref={scrollRef}
              className="relative max-h-[calc(100%-96px)] overflow-scroll px-4"
            >
              <div className="flex items-center space-x-2" id="rank_item">
                <div className="text-base font-medium text-sorra-gray-900">
                  選擇評價的產品
                </div>
                {isProductNotFilled ? (
                  <div className="flex items-center space-x-1 text-sm text-sorra-orange-warning">
                    <Image
                      src="/svgs/warning.svg"
                      alt=""
                      className="mt-1"
                      width={16}
                      height={16}
                    />
                    <span>必填欄位</span>
                  </div>
                ) : null}
                <div
                  className="commentPopup-addProduct flex cursor-pointer text-sm font-medium text-sorra-pink"
                  onClick={() => {
                    window.location = '/nominate';
                  }}
                >
                  <img src="/svgs/folder-add.svg" className="mr-[4px]"></img>
                  新增產品
                </div>
              </div>

              <div className="relative mb-[24px] mt-[8px] h-[48px] w-full">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[16px]">
                  <img src="/svgs/search.svg"></img>
                </div>
                <input
                  type="text"
                  id=""
                  className={`bg-gray-0 border ${
                    isProductNotFilled
                      ? 'border-sorra-orange-warning'
                      : 'border-gray-300'
                  } block h-full w-full rounded-lg p-2.5 pl-[56px] font-medium text-sorra-gray-900 focus:border-gray-800 focus:ring-0 focus:ring-offset-0`}
                  placeholder="美妝產品"
                  autoComplete="false"
                  autoCorrect="false"
                  value={searchProductKeywordState}
                  onFocus={() => pressSearchProductInputFocus(true)}
                  onBlur={() => {
                    window.setTimeout(function () {
                      if (searchProductKeywordState == '') {
                        pressSearchProductInputFocus(false);
                      }
                    }, 100);
                  }}
                  onChange={(event) => {
                    resetWarningState();
                    setSearchProductKeywordState(event.target.value);
                    setSearchProductIdState('');
                    setIsPostError(false);
                  }}
                />
                <button
                  className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                  onClick={() => {
                    setSearchProductKeywordState('');
                    setSearchProductIdState('');
                    setIsPostError(false);
                  }}
                >
                  {searchProductKeywordState != '' ? (
                    <Image
                      src="/svgs/clear.svg"
                      alt="clear"
                      width={14}
                      height={14}
                    />
                  ) : (
                    ''
                  )}
                </button>
              </div>

              <div
                className={
                  'space-y-[16px]' +
                  (searchProductInputFocusState == true ? ' block' : ' hidden')
                }
              >
                {blockSearchProductState}
              </div>

              <div
                className={
                  'mt-[24px] border-t-[1px] border-gray-300' +
                  (searchProductInputFocusState == true ? ' block' : ' hidden')
                }
              ></div>

              {/* <div>
                                    Selected Product ID : {searchProductIdState}
                                </div> */}

              <div className="mt-[24px] font-[500]" id="rank_ranking">
                <div className="flex items-center space-x-2">
                  <div className="text-base font-medium text-sorra-gray-900">
                    對此產品的整體評價
                  </div>
                  {isRankNotFilled ? (
                    <div className="flex items-center space-x-1 text-sm text-sorra-orange-warning">
                      <Image
                        src="/svgs/warning.svg"
                        alt=""
                        className="mt-1"
                        width={16}
                        height={16}
                      />
                      <span>必填欄位</span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="mt-[8px] flex items-center justify-between lg:justify-start lg:space-x-4">
                {arrCommentOverviewContent.map((item) => (
                  <div
                    className={`cursor-pointer rounded-[16px] border-[1px] px-[28px] py-[16px] text-[#4A4A4A] ${
                      commentOverviewState === item.mark
                        ? 'border-sorra-pink bg-sorra-pink-light'
                        : 'border-[#9B9B9B]'
                    }`}
                    onClick={() => setCommentOverviewState(item.mark)}
                  >
                    {item.title}
                  </div>
                ))}
              </div>

              <div className="mt-[24px] font-[500]" id="rank_comment">
                <div className="flex items-center space-x-2">
                  <div className="text-base font-medium text-sorra-gray-900">
                    對此產品的詳細評價
                  </div>
                  {isCommentNotFilled ? (
                    <div className="flex items-center space-x-1 text-sm text-sorra-orange-warning">
                      <Image
                        src="/svgs/warning.svg"
                        alt=""
                        className="mt-1"
                        width={16}
                        height={16}
                      />
                      <span>必填欄位</span>
                    </div>
                  ) : null}
                  {isCommentTooLong ? (
                    <div className="flex items-center space-x-1 text-sm text-sorra-orange-warning">
                      <Image
                        src="/svgs/warning.svg"
                        alt=""
                        className="mt-1"
                        width={16}
                        height={16}
                      />
                      <span>超出評價上限{commentCountMaxState}字</span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="relative mt-2">
                <div
                  className={`border pb-10 ${
                    isCommentNotFilled || isCommentTooLong
                      ? 'border-sorra-orange-warning'
                      : 'border-gray-300'
                  } relative w-full rounded-lg focus:border-gray-800`}
                >
                  {isCommentTutorialShow && !isCommentSessionShow ? (
                    <div
                      className="absolute bottom-0 left-0 right-0 top-0 z-[1]"
                      onClick={() => {
                        setIsCommentModalShow(true);
                        setIsCommentSessionShow(true);
                      }}
                    />
                  ) : null}
                  <textarea
                    className={`relative mt-[8px] h-[140px] w-full overflow-auto border-transparent text-start focus:border-transparent focus:ring-0`}
                    placeholder="留下您的評價，幫助更多想買的人！"
                    onChange={(e) => {
                      resetWarningState();
                      setDetailCommentState(e.target.value);
                      setDetailCommentCountState(e.target.value.length);
                      setCommentHintShow(false);
                    }}
                  />
                </div>
                <div className="absolute bottom-[16px] left-[16px] right-[16px] flex items-center justify-between">
                  <div className="text-[12px] font-[400] text-sorra-gray-800">{`現時：${detailCommentCountState}字`}</div>
                  <div className="flex items-center rounded-md bg-[#F5F5F5] px-[8px] py-[4px] text-[12px] text-sorra-gray-800">
                    {`超過${commentValidCountState}字評價`}+&nbsp;
                    <div className="flex h-[16px] w-[16px] items-center rounded-[50%] bg-sorra-pink-light">
                      <img
                        src="/svgs/sorra-logo-small.svg"
                        className="p-[4px]"
                      />
                    </div>{' '}
                    ${`${commentValidScoreState}`}
                  </div>
                </div>
              </div>

              {commentHintShow ? (
                <div className="mt-4 text-[14px] text-sorra-gray-800">
                  評價提示:
                  <br />
                  {hintText}
                </div>
              ) : null}

              {blockRankPointState}

              <div className="mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center font-[500]">
                    真實用家認證
                    <img
                      src="/svgs/information-circle.svg"
                      width={20}
                      height={20}
                      alt=""
                      className="ml-[8px] mt-[2px] cursor-pointer"
                      onClick={() => setIsUserAuthModalShow(true)}
                    />
                  </div>
                  <div className="flex items-center rounded-md bg-[#F5F5F5] px-[8px] py-[4px] text-[12px] text-sorra-gray-800">
                    上傳後額外+&nbsp;
                    <div className="flex h-[16px] w-[16px] items-center rounded-[50%] bg-sorra-pink-light">
                      <img
                        src="/svgs/sorra-logo-small.svg"
                        className="p-[4px]"
                      />
                    </div>{' '}
                    $10
                  </div>
                </div>
                {blockAuthImage}
              </div>

              <div className="mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center font-[500]">
                    附加照片
                    <img
                      src="/svgs/information-circle.svg"
                      width={20}
                      height={20}
                      alt=""
                      className="ml-[8px] mt-[2px]"
                      onClick={() => setIsAdditionalPhotoModalShow(true)}
                    />
                  </div>
                  <div className="flex items-center rounded-md bg-[#F5F5F5] px-[8px] py-[4px] text-[12px] text-sorra-gray-800">
                    上傳後額外+&nbsp;
                    <div className="flex h-[16px] w-[16px] items-center rounded-[50%] bg-sorra-pink-light">
                      <img
                        src="/svgs/sorra-logo-small.svg"
                        className="p-[4px]"
                      />
                    </div>{' '}
                    $5
                  </div>
                </div>
                {blockAdditionalImage}
              </div>

              {!isEmpty(errorMsg) ? (
                <div className="flex items-center gap-x-1 pt-[18px] text-[14px] font-normal text-[#9B1C1C]">
                  <Image
                    src="/svgs/error.svg"
                    width={16}
                    height={16}
                    alt=""
                    className="mt-1"
                  />
                  {errorMsg}
                  {/* 抱歉，您已評價過該產品！請1周後再追加評價。 */}
                </div>
              ) : null}

              <div
                className={classNames('pb-6', {
                  'pt-[22px]': isPostError,
                  'pt-10': !isPostError,
                })}
              >
                <button
                  className={`${
                    !isUploadProcessing
                      ? 'bg-sorra-pink'
                      : 'bg-sorra-pink-light'
                  } flex h-[48px] w-full flex-row items-center justify-center rounded-[8px] text-[#fff]`}
                  onClick={!isUploadProcessing ? postComment : null}
                  disabled={isUploadProcessing}
                >
                  {!isUploadProcessing ? (
                    <>
                      <Image
                        src="/svgs/chat.svg"
                        height={20}
                        width={20}
                        className="mr-[12px]"
                      />
                      <p>留評價</p>
                    </>
                  ) : (
                    <p>上傳評價中...</p>
                  )}
                </button>
              </div>
            </div>
          </div>
        </MaxWidthContainer>
      </div>

      <div
        className="fixed bottom-0 left-0 z-30 w-full pt-[24px]"
        style={{ pointerEvents: popupShown == false ? 'none' : 'auto' }}
        onClick={() => {
          if (popupShown == true) {
            setPopupShown(false);
            setExternalPopupShown(false);
          }
        }}
      >
        <MaxWidthContainer className="mb-[86px] flex justify-end px-4 lg:mb-[48px]">
          <div
            className={
              'z-40 h-[48px] w-[48px] cursor-pointer rounded-full bg-sorra-pink p-[12px] lg:h-[72px] lg:w-[72px] lg:p-[18px]' +
              (isUserLoggedIn == true ? '' : ' hidden')
            }
            style={{ pointerEvents: 'auto' }}
            onClick={() => {
              if (popupShown == true) {
                setPopupShown(false);
              } else {
                if (isUserLoggedIn == true) {
                  setPopupShown(true);
                } else {
                  // console.log('nigga')
                  redirectToLogin(pathname);
                }
              }
            }}
          >
            <img className="h-full w-full" src="/svgs/pencil.svg"></img>
          </div>
          <div
            className={
              'h-[58px] w-[52px] cursor-pointer lg:h-[88px] lg:w-[78px]' +
              (isUserLoggedIn == false ? '' : ' hidden')
            }
            style={{ pointerEvents: 'auto' }}
            onClick={() => {
              MixpanelConfig.track('signinup_from_flowingicon');
              redirectToLogin(pathname);
            }}
          >
            <img className="h-full w-full" src="/svgs/comment-login.svg"></img>
          </div>
        </MaxWidthContainer>
      </div>

      <UserAuthModal
        isShow={isUserAuthModalShow}
        setIsShow={setIsUserAuthModalShow}
        authImageRef={authImageRef}
      />
      <AdditionalPhotoModal
        isShow={isAdditionalPhotoModalShow}
        setIsShow={setIsAdditionalPhotoModalShow}
        additionalImageRef={additionalImageRef}
      />
      <CommentModal
        isShow={isCommentModalShow}
        setIsShow={setIsCommentModalShow}
      />
    </>
  );
};

export default CommentPopup;
